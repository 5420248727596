import { NextImage } from "@/next-js-app/components/common/NextImage/NextImage";
import { useMediaQuery } from "@mui/material";
import * as React from "react";
import { useSharedData } from "../../../contexts/SharedData";
import {
  mobileAndTabletMediaQuery,
  mobileMediaQuery,
} from "../../../utils/xxl-screen";
import { Slider } from "../../Common/Slider";
import { SLIDER_OPTIONS } from "../constants";
import { ProductImage } from "./ProductImage";
import { productCardImageSize } from "./product-image-helper";

const MobileImageCarouselOrSingleProductImage = ({
  columnAmount,
  imageAltText,
  isFocused = false,
  prioritizeImageLoad = false,
  listOfProductUrls,
  primaryImageUrl,
  showOnlyOneImage = false,
  positionInList = -1,
}: {
  columnAmount: number;
  imageAltText?: string;
  isFocused?: boolean;
  prioritizeImageLoad?: boolean;
  listOfProductUrls: string[];
  primaryImageUrl?: string;
  showOnlyOneImage?: boolean;
  positionInList?: number;
}) => {
  const isMobileOrTablet = useMediaQuery(mobileAndTabletMediaQuery);
  const isMobile = useMediaQuery(mobileMediaQuery);
  const { isReactApp } = useSharedData();
  const isAllowedToPrioImage =
    prioritizeImageLoad && isMobile ? positionInList < 2 : positionInList < 6;
  const imageSize = isMobile
    ? productCardImageSize.default.mobile
    : productCardImageSize.default.desktop;
  const products = listOfProductUrls.map((image, index) => {
    const isPrio = index === 0 && isAllowedToPrioImage;

    if (isReactApp) {
      return (
        <ProductImage
          imageUrl={image}
          alt={imageAltText}
          key={index}
          columnAmount={columnAmount}
          size={imageSize}
          fetchPriority={isPrio ? "high" : "low"}
          loading={isPrio ? "eager" : "lazy"}
        />
      );
    }

    return (
      <NextImage
        src={image}
        alt={imageAltText ?? "product image"}
        key={index}
        width={imageSize}
        height={imageSize}
        loading={isPrio ? "eager" : "lazy"}
        priority={isPrio}
      />
    );
  });

  if (isMobileOrTablet && listOfProductUrls.length > 1 && !showOnlyOneImage) {
    return (
      <Slider
        items={products}
        slidesConfig={{
          perView: SLIDER_OPTIONS.slidesPerView,
          spacing: SLIDER_OPTIONS.spacing,
        }}
        mode={SLIDER_OPTIONS.mode}
        dragSpeed={SLIDER_OPTIONS.dragSpeed}
        loadInitiallyOnlyFirstSlide={true}
        navigation={"ARROWS_SMALL"}
      />
    );
  }

  const [, secondImage] = listOfProductUrls;
  return (
    <ProductImage
      alt={imageAltText}
      columnAmount={columnAmount}
      fetchPriority={isAllowedToPrioImage ? "high" : "low"}
      focusImageUrl={secondImage}
      imageUrl={primaryImageUrl}
      isFocused={isFocused}
      loading={isAllowedToPrioImage ? "eager" : "lazy"}
      size={imageSize}
      addImageParameters={false}
    />
  );
};

export { MobileImageCarouselOrSingleProductImage };
