import { useSharedData } from "../../contexts/SharedData";
import { useMediaQuery } from "@mui/material";
import { laptopMediaQuery } from "react-app/src/utils/xxl-screen";
import { dispatchSetQuickShopProductCode } from "react-app/src/utils/xxl-event";
import { isNotEmpty } from "@xxl/common-utils";

export const useQuickshop = () => {
  const { featureToggles } = useSharedData().data;
  const { toggle_quick_shop } = featureToggles;
  const isLaptopSize = useMediaQuery(laptopMediaQuery);
  const isNextPage =
    typeof window === "undefined" || typeof window._sharedData === "undefined";

  const showQuickshop = isLaptopSize && toggle_quick_shop && isNextPage;

  const openQuickshop = (
    event: React.MouseEvent,
    productCode: string,
    ticket?: string
  ) => {
    event.preventDefault();
    event.stopPropagation();

    if (isNotEmpty(productCode)) {
      dispatchSetQuickShopProductCode({ productCode, ticket });
    }
  };

  return { showQuickshop, openQuickshop };
};
