import { BadgeType as BADGE_TYPE } from "@xxl/pim-api";
import React from "react";
import type {
  BadgeType,
  MultiChannelAvailability,
} from "../../utils/data-types";
import { ChannelTag } from "./ChannelTag/ChannelTag";
import { RibbonsWrapper } from "./Product.styled";
import { ProductBadge } from "./ProductBadge/ProductBadge";

type Props = {
  badges: BadgeType[];
  multiChannelAvailability: MultiChannelAvailability[];
};

export const ProductRibbons = ({ badges, multiChannelAvailability }: Props) => {
  const showChannelTag =
    multiChannelAvailability.length === 1 &&
    !badges.includes(BADGE_TYPE.PRESALE as BadgeType);
  const badgesLimit = showChannelTag ? 0 : 1;
  const showRibbons = showChannelTag || badges.length > 0;

  if (!showRibbons) {
    return null;
  }

  return (
    <RibbonsWrapper>
      {badges.map((badge, index) =>
        index <= badgesLimit ? (
          <ProductBadge badgeType={badge} key={badge} />
        ) : null
      )}
      {showChannelTag ? (
        <ChannelTag
          multichannelAvailability={multiChannelAvailability}
          isPDPImage={false}
        />
      ) : null}
    </RibbonsWrapper>
  );
};
